@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}

.mapboxgl-map {
  font-family: "Ford Antenna Regular" !important;
}
@font-face {
  font-family: "Ford Antenna Regular";
  src: url("./assets/fonts/Ford-Antenna-Regular.woff2");
}

@font-face {
  font-family: "Ford Antenna Light";
  src: url("./assets/fonts/Ford-Antenna-Light.woff2");
}

@font-face {
  font-family: "Ford Antenna Medium";
  src: url("./assets/fonts/Ford-Antenna-Medium.woff2");
}

@font-face {
  font-family: "Ford Antenna Bold";
  src: url("./assets/fonts/Ford-Antenna-Bold.woff2");
}

@layer base {
  html {
    font-family: "Ford Antenna Regular", system-ui, sans-serif;
  }
}
